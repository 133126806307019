import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import { createI18n } from 'vue-i18n';

const en = require('./locales/en.json');
const zh = require('./locales/zh.json');

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        "zh-cn": zh
    }
});

const app = createApp(App);
app.use(i18n);
app.use(Antd);
app.mount('#app');
