<template>
    <a-space direction="vertical" :style="{ width: '100%' }" :size="[0, 48]">
        <a-layout>
            <a-layout-header :style="headerStyle">
                <h1>Studio Tang 糖风玩</h1>
            </a-layout-header>
            <a-layout-content :style="contentStyle">
                <h3>欢迎来到Studio Tang！我们是一个2人游戏小团队，专注于微信、抖音小游戏的开发。
                    <br />
                    希望为您献上最好的游戏体验！
                </h3>

                <a-carousel :after-change="onChange" autoplay arrows>
                    <template #prevArrow>
                        <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                            <left-circle-outlined />
                        </div>
                    </template>
                    <template #nextArrow>
                        <div class="custom-slick-arrow" style="right: 10px">
                            <right-circle-outlined />
                        </div>
                    </template>
                    <div>
                        <a-image src="./img1.jpeg" style="width: 400px;" />
                    </div>
                    <div>
                        <a-image src="./img2.png" style="width: 400px;" />
                    </div>
                </a-carousel>
            </a-layout-content>
            <a-layout-header :style="footerStyle">
                <a href="https://beian.miit.gov.cn">津ICP备2024016450号-1</a>
            </a-layout-header>
        </a-layout>
    </a-space>
</template>

<script lang="ts" setup>
import type { CSSProperties } from 'vue';
import { onMounted } from 'vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
const onChange = (current: number) => {
    console.log(current);
};

const headerStyle: CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
};

const contentStyle: CSSProperties = {
    textAlign: 'center',
    // minHeight: 120,
    // lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#1f2b42',
};

const footerStyle: CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
};

onMounted(() => {
    document.title = "糖风玩"
});
</script>

<style>
body {
    background-color: #2a3c5f;
}
</style>

<style scoped>
:deep(.slick-slide) {
    text-align: center;
    height: 660px;
    line-height: 660px;
    background: #364d79;
    overflow: hidden;
}

:deep(.slick-arrow.custom-slick-arrow) {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    transition: ease all 0.3s;
    opacity: 0.3;
    z-index: 1;
}

:deep(.slick-arrow.custom-slick-arrow:before) {
    display: none;
}

:deep(.slick-arrow.custom-slick-arrow:hover) {
    color: #fff;
    opacity: 0.5;
}

:deep(.slick-slide h3) {
    color: #fff;
}
</style>